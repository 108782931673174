import axios from '@axios'
import useJwt from '@/auth/jwt/useJwt'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // (查詢)員工列表
    getPersonnelList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/admin/personnel', { headers: { token: useJwt.getToken() }, params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (新增)員工資料
    setPersonnelCreate(ctx, generalData) {
      return new Promise((resolve, reject) => {
        axios('/v1/api/admin/personnel/add', {
          method: 'POST',
          headers: {
            token: useJwt.getToken(),
          },
          data: {
            ...generalData,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
