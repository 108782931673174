<template>
  <div>
    <!-- 會員管理列表 -->
    <b-card no-body>
      <!-- 顯示個數/搜尋框 -->
      <div class="m-2">
        <b-row>
          <!-- 顯示個數 -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>顯示</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
          </b-col>

          <!-- 搜尋框 -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                debounce="500"
                class="d-inline-block mr-1"
                placeholder="搜尋..."
              />
              <b-button variant="primary">
                <span class="text-nowrap">新增</span>
              </b-button>
            </div>
          </b-col>

        </b-row>
      </div>

      <!-- 列表 -->
      <b-table
        ref="refPersonnelListTable"
        class="position-relative"
        :items="getPersonnelListData"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="查無資料"
        :sort-desc.sync="isSortDirDesc"
        @row-clicked="onRowSelected"
      >
        <!-- 欄位: 狀態 -->
        <template #cell(work)="data">
          <div>
            <b-badge
              v-if="!data.item.engage_date && !data.item.resign_date"
              variant="light-primary"
            >
              <span>尚未就職</span>
            </b-badge>

            <b-badge
              v-if="data.item.engage_date"
              variant="light-success"
            >
              <div
                v-b-tooltip.hover.focus.v-secondary
                :title="`${moment(data.item.engage_date).format('YYYY-MM-DD')}`"
                variant="gradient-primary"
              >
                <span>在職</span>
              </div>
            </b-badge>

            <b-badge
              v-if="data.item.resign_date"
              variant="light-secondary"
            >
              <div
                v-b-tooltip.hover.focus.v-secondary
                :title="`${moment(data.item.resign_date).format('YYYY-MM-DD')}`"
                variant="gradient-primary"
              >
                <span>離職</span>
              </div>
            </b-badge>
          </div>
        </template>

        <!-- 欄位: 姓名 -->
        <template #cell(name)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="40"
                :src="data.item.image ? `https://cdn.twhost.com.tw/storage/admin/img/${data.item.image}`: null"
                style="color:#dedede;"
              />
            </template>
            <b-link class="font-weight-bold d-block">
              {{ data.item.ch_name ? data.item.ch_name : '未知' }}
            </b-link>
            <small class="text-muted">{{ data.item.email ? data.item.email : '未知' }}</small>
          </b-media>
        </template>

        <!-- 欄位: 單位 -->
        <template #cell(unit)="data">
          <div style="min-width: 85px;">
            {{ data.item.unit ? data.item.unit : '---' }}
          </div>
        </template>

        <!-- 欄位: 職稱 -->
        <template #cell(auth)="data">
          <div style="min-width: 85px;">
            {{ data.item.power ? data.item.power : '---' }}
          </div>
        </template>

        <!-- 欄位: 時間 -->
        <template #cell(time)="data">
          <div
            v-b-tooltip.hover.focus.v-secondary
            :title="`${moment(data.item.created_at).format('HH:mm:ss')}`"
            variant="gradient-primary"
            style="min-width: 85px;"
          >
            <span>{{ moment(data.item.created_at).format("YYYY-MM-DD") }}</span>
          </div>
        </template>

        <!-- 欄位: 編輯狀態 -->
        <template #cell(state)="data">
          <b-badge
            :variant="resolveStateColor(data.item.state)"
          >
            <span>{{ resolveState(data.item.state) }}</span>
          </b-badge>
        </template>

        <!-- 欄位: 動作 -->
        <template #cell(actions)>
          <b-dropdown
            variant="link"
            no-caret
          >

            <template #button-content>
              <feather-icon
                icon="MoreHorizontalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item>
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">詳細</span>
            </b-dropdown-item>

            <b-dropdown-item>
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">編輯</span>
            </b-dropdown-item>

            <b-dropdown-item>
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">移除</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>

      <!-- 顯示頁數/分頁 -->
      <div class="mx-2 mb-2">
        <b-row>
          <!-- 顯示頁數 -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">第 {{ dataMeta.from }} - {{ dataMeta.to }} 筆，共 {{ dataMeta.of }} 筆</span>
          </b-col>

          <!-- 分頁 -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>

    </b-card>

  </div>
</template>

<script>
// API
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import moment from 'moment'
// UI
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BPagination, VBTooltip, BLink, BBadge, BDropdown, BDropdownItem,
  BMedia, BAvatar,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
// Component
import usePersonnelList from '../usePersonnel'
import useStoreModule from '../useStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    BLink,
    BBadge,
    BAvatar,
    BMedia,
    BDropdown,
    BDropdownItem,

    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  methods: {
    moment,
    // onRowSelected(items) {
    //   // this.$router.push({ name: 'admin-personnel-view', params: { uid: items.uid } })
    // },
  },
  setup() {
    // 註冊模組
    const PERSONNEL_ADMIN_STORE_MODULE_NAME = 'admin-personnel'

    if (!store.hasModule(PERSONNEL_ADMIN_STORE_MODULE_NAME)) store.registerModule(PERSONNEL_ADMIN_STORE_MODULE_NAME, useStoreModule)

    onUnmounted(() => {
      if (store.hasModule(PERSONNEL_ADMIN_STORE_MODULE_NAME)) store.unregisterModule(PERSONNEL_ADMIN_STORE_MODULE_NAME)
    })

    // 設定
    const {
      getPersonnelListData,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refPersonnelListTable,
      refetchData,

      resolveState,
      resolveStateColor,
    } = usePersonnelList()

    return {
      getPersonnelListData,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refPersonnelListTable,
      refetchData,

      resolveState,
      resolveStateColor,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
