import { ref, watch, computed } from '@vue/composition-api'
// import router from '@/router'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function usePersonnelList() {
  const toast = useToast()

  const refPersonnelListTable = ref(null)
  const perPage = ref(10)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 50, 100, 200]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const tableColumns = [
    { label: '編號', key: 'id' },
    { label: '任職狀態', key: 'work' },
    { label: '姓名', key: 'name' },
    { label: '單位', key: 'unit' },
    { label: '職稱', key: 'auth' },
    { label: '註冊日期', key: 'time' },
    { label: '編輯', key: 'state' },
    { label: '動作', key: 'actions' },
  ]

  const dataMeta = computed(() => {
    const localItemsCount = refPersonnelListTable.value ? refPersonnelListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const refetchData = () => refPersonnelListTable.value.refresh()

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const getPersonnelListData = (ctx, callback) => {
    store
      .dispatch('admin-personnel/getPersonnelList', {
        search: searchQuery.value,
        limit: perPage.value,
        page: currentPage.value,
        sort: sortBy.value,
        order: isSortDirDesc.value === true ? 'asc' : 'desc',
        offset: dataMeta.value.from,
        _: Date.now(),
      })
      .then(response => {
        if (response.data.success !== true) {
          toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          return
        }
        const { data, total } = response.data.data

        callback(data)
        totalUsers.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: '提取使用者列表時出錯',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const resolveState = state => {
    if (state === 0) return '填寫中'
    if (state === 1) return '待審查'
    if (state === 2) return '完成'
    if (state === 3) return '變更申請'
    return '錯誤'
  }

  const resolveStateColor = state => {
    if (state === 0) return 'light-primary'
    if (state === 1) return 'light-warning'
    if (state === 2) return 'light-success'
    if (state === 3) return 'light-info'
    return 'light-danger'
  }

  return {
    getPersonnelListData,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refPersonnelListTable,
    refetchData,

    resolveState,
    resolveStateColor,
  }
}
